import React, { Component } from 'react'
import { connect } from 'react-redux'
import { openLoader, closeLoader } from '../../../../../../redux-store/loader'
import { closeModal, openModal } from '../../../../../../redux-store/modal'
import { Accordion } from 'semantic-ui-react'
import ProLicensesAccordion from './ProLicensesAccordion'

class ProLicenses extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeIndex: 0
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {

        const { activeIndex } = this.state;

        return (
            <React.Fragment>
                <Accordion>
                    <ProLicensesAccordion {...this.props} activeIndex={activeIndex} type="premium" index={0} handleClick={this.handleClick} />
                    <ProLicensesAccordion {...this.props} activeIndex={activeIndex} type="pro" index={1} handleClick={this.handleClick} />
                </Accordion>
            </React.Fragment>
        )
    }
}


export default connect(null, { openLoader, closeLoader, closeModal, openModal })(ProLicenses);