import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const getUserPayments = async userId => await AxiosService.get(Config.server.api.paymentsApi + `admin/user/${userId}`, {}, true);
const getUserLicenses = async userId => await AxiosService.get(Config.server.api.paymentsApi + `admin/user/${userId}/licenses`, {}, true);
const getProLicenses = async proUserId => await AxiosService.get(Config.server.api.paymentsApi + `admin/pro/${proUserId}/licenses`, {}, true);
const extendUserLicense = async (userId, payload) => await AxiosService.post(Config.server.api.paymentsApi + `admin/user/${userId}/licenses`, payload , true);
const extendProLicense = async (proUserId, payload) => await AxiosService.post(Config.server.api.paymentsApi + `admin/pro/${proUserId}/licenses`, payload , true);
const getPayments = async () => await AxiosService.get(Config.server.api.paymentsApi + `admin`, {}, true);
const getUserPaymentInvoice = async (userId, paymentId) => await AxiosService.getBlob(Config.server.api.paymentsApi + `admin/user/${ userId }/invoice/${ paymentId }`, true);
const getInvestements = async () => await AxiosService.get(Config.server.api.paymentsApi + `admin/ygb/investment`, {}, true);
const getCommissions = async type => await AxiosService.get(Config.server.api.paymentsApi + `commission/${type}`, {}, true);

export default {
    extendUserLicense,
    extendProLicense,
    getUserLicenses,
    getProLicenses,
    getPayments,
    getUserPayments,
    getUserPaymentInvoice,
    getInvestements,
    getCommissions
}