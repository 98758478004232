import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { openLoader, closeLoader } from '../../../../../../redux-store/loader'
import { closeModal, openModal } from '../../../../../../redux-store/modal'
import PaymentModel from '../../../../../../data/models/payment/payment'
import I18n from '../../../../../../i18n'
import { Form, Button } from 'semantic-ui-react'
import { required } from '../../../../../../config/validations';
import CustomInput from '../../../../../components/form/input'

class ExtendProLicenses extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  handleExtendProLicense = async ({ days }) => {

    let messageError = {
      headerTitle: "Error",
      title: I18n.t('messages.modal.commonError'),
      buttons: [

        {
          text: I18n.t('actions.accept'),
          callback: () => this.props.closeModal()

        }

      ]
    };

    let messageCorrect = {
      title: I18n.t('messages.modal.proLicenseExtended', { days }),
      buttons: [

        {
          text: I18n.t('actions.accept'),
          callback: () => this.props.closeModal()

        }

      ]
    };


    try {

      this.props.openLoader();
      await PaymentModel.extendProLicense(this.props.userid, { days: parseInt(days) });
      this.props.openModal(messageCorrect);
      this.props.getProLicences();

    } catch (error) {

      this.props.closeLoader();
      this.props.openModal(messageError);

    } finally {

      this.props.closeLoader();

    }

  }

  render() {

    return (
      <Form name="proLicenseForm" onSubmit={this.props.handleSubmit(this.handleExtendProLicense)}>
        <Field
          component={CustomInput}
          placeholder={''}
          name="days"
          inline={true}
          validate={[required]}
          restrictions={[{ numeric: true }]}
        />
        <Form.Field>
          <Button floated="left" content={I18n.t('user.info.extend')} icon='plus' labelPosition='right' size="mini" />
          <div style={{ clear: "both" }}></div>
        </Form.Field>
      </Form>
    )
  }
}


export default reduxForm({
  form: 'proLicenseForm',
  touchOnBlur: true,
  touchOnChange: false,
  initialValues: {
    days: 0
  }
})(connect(null, { openLoader, closeLoader, closeModal, openModal })(ExtendProLicenses));