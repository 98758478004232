// Third party library
import _ from 'lodash';
import Moment from 'moment';
import { connect } from 'react-redux';
import React from 'react';
import { Segment, Header, Table, Pagination, Menu, Input, Form, Icon, Select } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Link } from 'react-router-dom';

// Models
import SecurityModel from '../../../../data/models/security/security';

// Redux Store
import { openLoader, closeLoader } from '../../../../redux-store/loader';

// Constants
import { countries } from './countries';

// Locales
import I18n from '../../../../i18n';

class ProList extends React.Component {

    constructor(props) {

        super(props);

        this.genderValues = {
            1 : 'Masculino',
            2 : 'Femenino'
        };

        this.state = {
            roleFilteredValue: 'all',
            loaded: false,
            users: [],
            pageNumber: 1,
            pageSize: 0,
            recordsPerPage: 20,
            column: 'createdAt',
            direction: null,
            search: '',
            filteredUsers: [],
            startDate: null,
            endDate: null,
            activeAccount: '',
            notActiveAccounts: 0,
            roleOptions: [{
                key: 0,
                text: "Todos",
                value: 'all'
            },
            {
                key: 1,
                text: "Admin",
                value: 'admin'
            },
            {
                key: 3,
                text: "Advisor",
                value: 'advisor'
            },
            {
                key: 4,
                text: "Pretoriano",
                value: 'pretorian'
            },
            {
                key: 5,
                text: "Pretor",
                value: 'pretor'
            },
            {
                key: 6,
                text: "Premium",
                value: 'premium'
            }]
        };

    }

    componentDidMount() {

        this.getUsers();

    }

    getUsers = async () => {

        let countNotActives = 0;

        try {

            this.props.openLoader()
            const users = await SecurityModel.getPros();
            users.data = _.filter(users.data, user => !!user.createdAt);

            console.log(users.data)

            _.each(users.data, user => {

                user.countryName = countries[user.country] || 'País sin definir';
                user.genderName = this.genderValues[user.gender] || 'Género sin definir';
                user.activeAccount = user.activeAccount || false;
                user.plan = user.plan || "Free";
                user.linkedStudents = user.linkedStudents || 0;
                user.unlinkedStudents = user.unlinkedStudents || 0;
                user.lastAccess = user.lastAccess || null;
                if (!user.activeAccount) {

                    countNotActives++;

                }

            });

            this.setState({
                filteredUsers: _.sortBy(users.data, [this.state.column]).reverse(),
                users: users.data,
                pageSize: Math.ceil(users.data.length / this.state.recordsPerPage),
                notActiveAccounts: countNotActives
            });

        } catch (error) {

            console.error('error', error);

        } finally {

            this.props.closeLoader();
            this.setState({ loaded: true });

        }

    }

    onPageChange = (e, pageInfo) => {

        this.setState({ pageNumber: pageInfo.activePage });

    }

    paginate = () => this.state.filteredUsers.slice((this.state.pageNumber - 1) * this.state.recordsPerPage, this.state.pageNumber * this.state.recordsPerPage);

    onFilter = () => {

        let filteredUsers = this.state.users;

        const { roleFilteredValue, recordsPerPage, search, startDate, endDate } = this.state;

        filteredUsers = filteredUsers.filter(user => {

            if ((!!search.length && !_.get(user, 'name', '').toLowerCase().includes(search.toLowerCase()) && !_.get(user, 'email', '').toLowerCase().includes(search.toLowerCase()))
                || (startDate && Moment(user.createdAt).startOf('day').isBefore(Moment(startDate)))
                || (endDate && Moment(user.createdAt).startOf('day').isAfter(Moment(endDate)))
                || (roleFilteredValue && roleFilteredValue !== 'all' && !_.get(user, 'role', []).includes(roleFilteredValue))
            ) {

                return false;

            }

            return true;

        });

        this.setState({ filteredUsers, pageSize: Math.ceil(filteredUsers.length / recordsPerPage), pageNumber: 1 });

    }

    onFilterByRole = roleFilteredValue => this.setState({ roleFilteredValue }, () => this.onFilter());

    onFilterListByName = search => this.setState({ search }, () => this.onFilter());

    onFilterAtStartDate = startDate => this.setState({ startDate }, () => this.onFilter());

    onFilterAtEndDate = endDate => this.setState({ endDate }, () => this.onFilter());

    handleSort = clickedColumn => () => {

        const { column, filteredUsers, direction } = this.state;

        if (column !== clickedColumn) {

            this.setState({
                column: clickedColumn,
                filteredUsers: clickedColumn === 'createdAt' ? filteredUsers.sort((a, b) => Moment(a[clickedColumn]).diff(b[clickedColumn])) : _.sortBy(filteredUsers, [clickedColumn]),
                direction: 'ascending',
            })

            return;

        }

        this.setState({
            filteredUsers: filteredUsers.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending'
        });

    }

    moreInfo = userId => this.props.history.push(`/home/pro/info/${userId}`)

    onRenderTable = () => {

        const { column, direction, roleFilteredValue } = this.state;

        return (
            <Table celled selectable sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell onClick={ this.handleSort('email') } sorted={ column === 'email' ? direction : null }>{ I18n.t('user.list.email') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('countryName') } sorted={ column === 'countryName' ? direction : null }>{ I18n.t('user.list.country') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('genderName') } sorted={ column === 'genderName' ? direction : null }>{ I18n.t('user.list.gender') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('name') } sorted={ column === 'name' ? direction : null }>{ I18n.t('user.list.name') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('surname') } sorted={ column === 'surname' ? direction : null }>{ I18n.t('user.list.surname') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('plan') } sorted={ column === 'plan' ? direction : null }>{ I18n.t('pro.list.plan') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('linkedStudents') } sorted={ column === 'linkedStudents' ? direction : null }>{ I18n.t('pro.list.linkedStudents') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('linkedStudents') } sorted={ column === 'linkedStudents' ? direction : null }>{ I18n.t('pro.list.unlinkedStudents') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('createdAt') } sorted={ column === 'createdAt' ? direction : null }>{ I18n.t('user.list.registerDate') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('createdAt') } sorted={ column === 'createdAt' ? direction : null }>{ I18n.t('pro.list.lastAccess') }</Table.HeaderCell>
                        { roleFilteredValue === 'advisor' &&
                            <Table.HeaderCell >{ I18n.t('user.list.verification') }</Table.HeaderCell>
                        }
                        <Table.HeaderCell >{ I18n.t('user.list.moreInfo') }</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { this.paginate().map(user => (
                        <Table.Row key={ user._id } negative={!user.activeAccount}>
                            <Table.Cell>{ user.email || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell>{ user.countryName || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell>{ user.genderName || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell>{ user.name || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell>{ user.surname || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell>{ user.plan || "Free" }</Table.Cell>
                            <Table.Cell>{ user.linkedStudents || 0 }</Table.Cell>
                            <Table.Cell>{ user.unlinkedStudents || 0 }</Table.Cell>
                            <Table.Cell>{ user.createdAt ? Moment(user.createdAt).format('DD/MM/YYYY HH:mm:ss') : I18n.t('user.info.noCreatedAt') }</Table.Cell>
                            <Table.Cell>{ user.lastAccess ? Moment(user.lastAccess).format('DD/MM/YYYY HH:mm:ss') : I18n.t('user.info.noCreatedAt') }</Table.Cell>
                            { roleFilteredValue === 'advisor' &&
                                <Table.Cell>{ user.advisorVerified ? I18n.t('user.list.yes'): I18n.t('user.list.no') }</Table.Cell>
                            }
                            <Table.Cell style={{ textAlign: 'center' }}>
                                { user.activeAccount && <Link to={{ pathname: `/home/pro/info/${user._id}` }} target="blank" ><Icon name="info"></Icon></Link> }
                            </Table.Cell>
                        </Table.Row>
                    )) }
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={ roleFilteredValue === 'advisor' ? 12 : 11 }>
                            <Menu floated='right'>
                                <Pagination
                                    onPageChange={ this.onPageChange }
                                    boundaryRange={ 1 }
                                    defaultActivePage={ 1 }
                                    prevItem={ null }
                                    nextItem={ null }
                                    siblingRange={ 2 }
                                    totalPages={ Math.ceil(this.state.filteredUsers.length / this.state.recordsPerPage) }
                                />
                            </Menu>
                            <div style={{ clear: "both" }}></div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );

    }

    render() {

        const { notActiveAccounts, roleFilteredValue, roleOptions } = this.state;

        return (
            <Segment>
                <Header as='h3'>{ I18n.t('user.list.userList') } : { this.state.users.length } - { I18n.t('user.list.downs') } : { notActiveAccounts }</Header>
                <div>
                    <Form>
                        <Form.Group inline>
                            <Form.Field>
                                <label>Nombre de usuario</label>
                                <Input value={ this.state.search } onChange={ e => this.onFilterListByName(e.target.value) } placeholder="Buscar por nombre o email"/>
                            </Form.Field>
                            <Form.Field>
                                <label>Fecha de inicio</label>
                                <SemanticDatepicker onChange={ (e, data) => this.onFilterAtStartDate(data.value) } value={ this.state.startDate ? new Date(this.state.startDate) : this.state.startDate} format='DD/MM/YYYY' />
                            </Form.Field>
                            <Form.Field>
                                <label>Fecha final</label>
                                <SemanticDatepicker onChange={ (e, data) => this.onFilterAtEndDate(data.value) } value={ this.state.endDate ? new Date(this.state.endDate) : this.state.endDate } format='DD/MM/YYYY' />
                            </Form.Field>
                            <Form.Field>
                                <label>Roles</label>
                                <Select value={roleFilteredValue} onChange={(e, { value }) => this.onFilterByRole(value) } options={roleOptions} />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </div>
                { this.state.loaded && this.onRenderTable() }
            </Segment>
        );

    }

}

export default connect(null, { openLoader, closeLoader })(ProList);