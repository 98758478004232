import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { openLoader, closeLoader } from '../../../../../../redux-store/loader'
import { closeModal, openModal } from '../../../../../../redux-store/modal'
import PaymentModel from '../../../../../../data/models/payment/payment'
import I18n from '../../../../../../i18n'
import { Segment, Header, Table, AccordionTitle, Icon, AccordionContent } from 'semantic-ui-react'
import moment from 'moment'
import ExtendProLicenses from './ExtendProLicenses'
import ExtendPremiumLicenses from './ExtendPremiumLicenses'

class ProLicensesAccordion extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeIndex: 0,
            licenses: [],
            column: 'startDate',
            direction: 'descending',
            originalData: []
        }
    }

    componentDidMount() {

        this.getLicenses();

    }

    getLicenses = async () => {

        let message = {
            headerTitle: "Error",
            title: I18n.t('messages.modal.commonError'),
            buttons: [

                {
                    text: I18n.t('actions.accept'),
                    callback: () => this.props.closeModal()

                }

            ]
        };

        try {

            this.props.openLoader();

            let response = null;

            if(this.props.type === 'pro'){
                response = await PaymentModel.getProLicenses(this.props.userid);
            }else if (this.props.type === 'premium'){
                response = await PaymentModel.getUserLicenses(this.props.userid);
            }

            if (response.data) {

                this.setState({ licenses: _.orderBy(response.data, ['startDate'], ['desc']), originalData: response.data });

            }

        } catch (error) {

            this.props.closeLoader();
            this.props.openModal(message);

        } finally {

            this.props.closeLoader();

        }

    }

    handleSort = (clickedColumn) => () => {

        const { column, direction, originalData } = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                licenses: _.orderBy(originalData, [clickedColumn], ['asc']),
                direction: 'ascending',
            })

            return
        }

        this.setState({
            licenses: _.orderBy(originalData, [clickedColumn], [direction === 'ascending' ? 'desc' : 'asc']),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

    }

    render() {

        const { licenses, column, direction } = this.state;

        const {index, activeIndex, type, handleClick} = this.props;

        const title = type === 'pro' ? I18n.t('pro.info.licenseList') : I18n.t('user.info.licenseList');

        const editTitle = type === 'pro' ? I18n.t('pro.info.extendProLicense') : I18n.t('user.info.extendLicensePremium');

        return (
            <React.Fragment>
                <Segment>
                    <AccordionTitle
                        active={activeIndex === index}
                        index={index}
                        onClick={handleClick}
                    >
                        <Icon name='dropdown' />
                        {title}
                    </AccordionTitle>
                    <AccordionContent active={activeIndex === index}>
                        <Table celled selectable sortable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell onClick={this.handleSort('startDate')} sorted={column === 'startDate' ? direction : null}>{I18n.t('user.info.startDate')}</Table.HeaderCell>
                                    <Table.HeaderCell onClick={this.handleSort('endDate')} sorted={column === 'endDate' ? direction : null}>{I18n.t('user.info.endDate')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {licenses.map(license => (
                                    <Table.Row key={license._id} >
                                        <Table.Cell>{moment(license.startDate).format('DD/MM/YYYY')}</Table.Cell>
                                        <Table.Cell>{moment(license.endDate).format('DD/MM/YYYY')} </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </AccordionContent>
                </Segment>
                <Segment>
                    <Header as="h3">{editTitle}</Header>
                    {
                        type === 'pro' ? 
                        <ExtendProLicenses {...this.props} getProLicences={() => this.getLicenses()} /> : 
                        <ExtendPremiumLicenses {...this.props} getUserLicences={() => this.getLicenses()} />
                    }
                </Segment>
            </React.Fragment>
        )
    }
}


export default connect(null, { openLoader, closeLoader, closeModal, openModal })(ProLicensesAccordion);