import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Form, Segment, Header, Icon, Grid, Button, Table } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import I18n from '../../../../../../../i18n';
import CustomInput from '../../../../../../components/form/input';
import { required, requiredArray } from '../../../../../../../config/validations';
import Select from '../../../../../../components/form/Select';
import Wysiwyg from '../../../../../../components/form/Wysiwyg';
import TagList from '../../../../../../components/form/tagList';
import InputRadio from '../../../../../../components/form/inputRadio';
import CustomCheckBox from '../../../../../../components/form/checkbox';
import CheckboxList from '../../../../../../components/form/checkBoxList';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => <Icon name='arrows alternate' />);

const SortableItem = SortableElement(({ value, idx }) => (
    <Table.Row>
        <Table.Cell textAlign='center' collapsing><DragHandle /></Table.Cell>
        <Table.Cell textAlign='center' collapsing>{ idx + 1 }</Table.Cell>
        <Table.Cell collapsing><img style={{ maxWidth: '140px', width: '100%' }} src={ value.thumbUrl } alt="" /></Table.Cell>
        <Table.Cell>{ value.title.ES }</Table.Cell>
        <Table.Cell>{ value._id }</Table.Cell>
    </Table.Row>
));

const SortableList = SortableContainer(({items }) => (
    <Table celled selectable sortable>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell collapsing>{ I18n.t('practice.sort') }</Table.HeaderCell>
                <Table.HeaderCell collapsing>{ I18n.t('practice.index') }</Table.HeaderCell>
                <Table.HeaderCell collapsing>{ I18n.t('practice.thumb') }</Table.HeaderCell>
                <Table.HeaderCell>{ I18n.t('practice.name') }</Table.HeaderCell>
                <Table.HeaderCell>{ 'id' }</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            { items.map((item, index) => <SortableItem key={ `item-${ index }` } index={ index } idx={ index } value={ item }/>)}
        </Table.Body>
    </Table>
));


class ProgramForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            practiceTypesOptions: {
                a: [
                    {
                        value: 'regular'
                    },
                    {
                        value: 'therapy'
                    },
                    {
                        value: 'restorative'
                    }
                ],
                b: [
                    {
                        value: 'ascendent'
                    },
                    {
                        value: 'descendent'
                    }
                ],
                c: [
                    {
                        value: 'dynamic'
                    },
                    {
                        value: 'static'
                    }
                ],
                d: [
                    {
                        value: 'unabridged'
                    },
                    {
                        value: 'mixed'
                    }
                ]
            },
            practiceLevels: [
                {
                    key: 1,
                    text: "Principiante",
                    value: '01'
                },
                {
                    key: 2,
                    text: "Avanzado",
                    value: '00'
                }
            ]
        }
    }

    onRemoveType = index => this.props.change(`types[${index}]`, 'none');

    onSortEnd = ({oldIndex, newIndex} ) => this.props.onOrder(oldIndex, newIndex);

    onSubmit = data => this.props.onSave(data);

    renderSortableSequenceList = () => {

        const { sequences } = this.props;

        return (

            <SortableList 
                helperClass="dragging-helper-class" 
                useDragHandle 
                items={ sequences } 
                onSortEnd={ this.onSortEnd } 
            />

        );

    }

    render() {

        const  { practiceTypesOptions, practiceLevels } = this.state;
        const { categories, sequences } = this.props;

        return (
            <Form noValidate onSubmit={this.props.handleSubmit(this.onSubmit)} name="programForm">
                <Segment id="general">
                    <Header as='h3'>{ I18n.t('practice.general') }</Header>
                    <Form.Group inline>
                        <Field
                            component={ CustomCheckBox }
                            name="public"
                            label={ I18n.t('practice.public') }
                            props={{ value: 1 }}
                            />
                            &nbsp;
                            &nbsp;
                        <Field
                            component={ CustomCheckBox }
                            name="public"
                            label={ I18n.t('practice.premium') }
                            props={{ value: 0 }}
                            />
                            &nbsp;
                            &nbsp;
                        <Field
                            component={ CustomCheckBox }
                            name="public"
                            label={ I18n.t('practice.private') }
                            props={{ value: 2 }}
                            />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.title') }
                            name="title.ES"
                            label={ I18n.t('practice.title') }
                            validate={ [required] }
                            />
                        <Field
                            component={ Select }
                            name="level"
                            label={ I18n.t('practice.level') }
                            options={ practiceLevels }
                            />
                    </Form.Group>
                        <Field
                            component={ Wysiwyg}
                            name="description.ES"
                            label={ I18n.t('practice.description') }
                            validate={ [required] }/>
                    <Form.Group widths='equal'>
                        <Field
                            component={ TagList }
                            name="tags"
                            label={ I18n.t('practice.addTags') }
                            placeholder={ I18n.t('practice.addTag') }
                            validate={ [requiredArray] }
                            />
                        <Field
                            component={ TagList }
                            name="targets"
                            label={ I18n.t('practice.addTargets') }
                            placeholder={ I18n.t('practice.addTargets') }
                            validate={ [requiredArray] }
                            />
                    </Form.Group>
                </Segment>
                <Segment id="types">
                    <Header as='h3'>{ I18n.t('practice.practiceType') }</Header>
                    <Grid columns='equal' stackable>
                        <Grid.Column>
                            <Segment>
                                <Form.Field>
                                    <label>{ I18n.t('practice.groupOne') }</label>
                                    <Field
                                        component={ InputRadio }
                                        options={ practiceTypesOptions['a'] }
                                        name="types[0]"
                                        label={I18n.t('practice.groupOne')}
                                    />
                                </Form.Field>
                                <Button type="button" floated="right" content='borrar' icon='close' labelPosition='right' onClick={ () => this.onRemoveType(0) } size="mini" />
                                <div style={{ clear: "both" }}></div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <Form.Field>
                                    <label>{ I18n.t('practice.groupTwo') }</label>
                                    <Field
                                        component={ InputRadio }
                                        options={ practiceTypesOptions['b'] }
                                        name="types[1]"
                                    />
                                </Form.Field>
                                <Button type="button" floated="right" content='borrar' icon='close' labelPosition='right' onClick={ () => this.onRemoveType(1) } size="mini" />
                                <div style={{ clear: "both" }}></div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <Form.Field>
                                    <label>{ I18n.t('practice.groupThree') }</label>
                                    <Field
                                        component={ InputRadio }
                                        options={ practiceTypesOptions['c'] }
                                        name="types[2]"
                                    />
                                </Form.Field>
                                <Button type="button" floated="right" content='borrar' icon='close' labelPosition='right' onClick={ () => this.onRemoveType(2) } size="mini" />
                                <div style={{ clear: "both" }}></div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <Form.Field>
                                    <label>{ I18n.t('practice.groupFour') }</label>
                                    <Field
                                        component={ InputRadio }
                                        options={ practiceTypesOptions['d'] }
                                        name="types[3]"
                                    />
                                </Form.Field>
                                <Button type="button" floated="right" content='borrar' icon='close' labelPosition='right' onClick={ () => this.onRemoveType(3) } size="mini" />
                                <div style={{ clear: "both" }}></div>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Segment id="categories">
                    <Header as='h3'>{ I18n.t('practice.categories') }</Header>
                    <Field
                        component={ CheckboxList }
                        options={ categories }
                        name="categories"
                        validate={ [requiredArray] }
                    />
                </Segment>
                <Segment id="resources">
                    <Header as='h3'>{ I18n.t('practice.resources') }</Header>
                    <Form.Group widths='equal'>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.thumbUrl') }
                            name="thumbUrl"
                            label={ I18n.t('practice.thumbUrl') }
                            validate={ [required] }
                            />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.imageUrl') }
                            name="imageUrl"
                            label={ I18n.t('practice.imageUrl') }
                            validate={ [required] }
                            />
                         <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.videoUrl') }
                            name="videoUrl"
                            label={ I18n.t('practice.videoUrl') }
                            />
                    </Form.Group>
                </Segment>
                <Segment>
                    <Header as='h3'>{ I18n.t('practice.sequences') }</Header>
                     {!_.isEmpty(sequences) ? <React.Fragment>{this.renderSortableSequenceList()}</React.Fragment> : <p>No se han encontrado secuencias asociadas a este programa</p> }
                </Segment>
                <Button floated="right" type='submit' primary>{ I18n.t('actions.save') }</Button>
                <Button floated="right" type='button' onClick={ () => this.props.history.push('/home/sequence') }>{ I18n.t('actions.cancel') }</Button>
                <div style={{ clear: "both" }}></div>
            </Form>
        );
    }
}

ProgramForm = connect(state => {

    const reduxFormState = _.cloneDeep(_.get(state, 'form.programForm.values', {}));

    return {
        reduxFormState
    };

})(ProgramForm);

export default reduxForm({
    form: 'programForm',
    touchOnBlur: true,
    touchOnChange: true,
})(connect(null, {  })(ProgramForm));