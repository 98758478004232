import React from 'react';
import { Tab } from 'semantic-ui-react';

// Esto es lo mismo que para los usuarios, ya que los profesores también son usuarios
import UserActions from '../../user/info/components/actions';
import UserGeneralInfo from '../../user/info/components/general';
import UserPayments from '../../user/info/components/payments';
import UserMatrix from '../../user/info/components/matrix';
import UserDiagnosis from '../../user/info/components/diagnosis';
import UserSessions from '../../user/info/components/sessions';
import UserAccesss from '../../user/info/components/access';
import UserProducts from '../../user/info/components/products';
import UserRoles from '../../user/info/components/roles';
import Assignments from '../../user/info/components/assignments';
import UserProStudentList from './components/UserProStudentList';
import ProLicenses from './components/licenses';

import '../../user/info/info.scss';

const panes = [
    { menuItem: 'General', render: (props) => <UserGeneralInfo {...props} /> },
    { menuItem: 'Accesos', render: (props) => <UserAccesss {...props} /> },
    { menuItem: 'Pagos', render: (props) => <UserPayments {...props} /> },
    { menuItem: 'Alumnos', render: (props) => <UserProStudentList {...props} /> },
    { menuItem: 'Mensajes', render: (props) => <UserActions {...props} /> },
    { menuItem: 'Matriz', render: (props) => <UserMatrix {...props} /> },
    { menuItem: 'Diagnóstico', render: (props) => <UserDiagnosis {...props} /> },
    { menuItem: 'Licencias', render: (props) => <ProLicenses {...props} /> },
    { menuItem: 'Sesiones', render: (props) => <UserSessions {...props} /> },
    { menuItem: 'Productos', render: (props) => <UserProducts {...props} /> },
    { menuItem: 'Roles', render: (props) => <UserRoles {...props} /> },
    { menuItem: 'Asignaciones', render: (props) => <Assignments {...props} /> }
];

//TRICK Para forzar un render cada vez que haya cambios en el props url userid añado un key al componente tab con el valor del userid
function userProInfoTabs(props) {
    
    return (
        <Tab key={props.match.params.proUserId} history={props.history} userid={props.match.params.proUserId} menu={{ secondary: true, pointing: true, className: "wrapped-tabs" }} panes={ panes } renderActiveOnly={ true } defaultActiveIndex={ 0 } />
    )
}

export default userProInfoTabs;