// Third party libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Redux
import { openLoader, closeLoader } from '../../../../redux-store/loader';

// Models
import StatsModel from '../../../../data/models/stats/stats';

// Components
import { Header, Segment } from 'semantic-ui-react';
import PieGraphContainer from './components/graphs/pie';

// Styles
import '../styles/styles.scss';

const pieGenderOptions = {
    property: 'gender',
    categories: [{
        name: 'Femenino',
        transform: item => item.gender === 1,
        id: 'a'
    }, {
        name: 'Masculino',
        transform: item => item.gender === 2,
        id: 'b'
    }]
};

const pieStudentOptions = {
    property: 'isPremium',
    categories: [{
        name: 'Premium',
        transform: item => item.isPremium === true,
        id: 'a'
    }, {
        name: 'Lite',
        transform: item => item.isPremium === false,
        id: 'b'
    }]
};

class ProStats extends Component {

    constructor(props) {

        super(props);

        this.state = {
            proUsers: [],
            proUsersFiltered: [],
            studentUsers: [],
            studentUsersFiltered: []
        }

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        const { openLoader, closeLoader } = this.props;

        try {

            openLoader();

            const proUsers = await StatsModel.getProUsers();
            const students = await StatsModel.getStudents();

            this.setState({ 
                proUsers: proUsers?.data || [],
                proUsersFiltered: proUsers?.data || [],
                studentUsers: students?.data || [],
                studentUsersFiltered: students?.data || []
            });          

        } catch (error) {

            console.error(error);

        } finally {

            closeLoader();

        }

    }

    render() {

        const { proUsersFiltered, studentUsersFiltered } = this.state;

        return (
            <>
                <Segment raised>
                    <Header as='h3'>Número de profesores por género</Header>
                    <div className="container-stats">
                        <div className="block-stats" >
                            <PieGraphContainer data={proUsersFiltered} options={pieGenderOptions}/>
                        </div>
                    </div>
                </Segment>
                <Segment raised>
                    <Header as='h3'>Número de alumnos Premium vs. Lite</Header>
                    <div className="container-stats">
                        <div className="block-stats" >
                            <PieGraphContainer data={studentUsersFiltered} options={pieStudentOptions}/>
                        </div>
                    </div>
                </Segment>
            </>
        );

    }

}

export default connect(null, { openLoader, closeLoader })(ProStats);