import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const readReducedDiagnosis = async (startDate, endDate) => AxiosService.get(Config.server.api.baseUrl + 'stats/diagnosis/reduced/filter?startDate=' + startDate + '&endDate=' + endDate, false);
const getReferredUsers = async () => await AxiosService.get(Config.server.api.statsApi + 'admin/user/referred', {}, true);
const getUsersChallenges = async () => await AxiosService.get(Config.server.api.statsApi + 'admin/user/challenge', {}, true);
const getFreeConsultationsUsers = async () => await AxiosService.get(Config.server.api.statsApi + 'admin/user/freeConsultation', {}, true);
const getProUsers = async () => await AxiosService.get(Config.server.api.statsApi + 'admin/pro', {}, true);
const getStudents = async () => await AxiosService.get(Config.server.api.statsApi + 'admin/pro/students', {}, true);

export default {
    readReducedDiagnosis,
    getReferredUsers,
    getUsersChallenges,
    getFreeConsultationsUsers,
    getProUsers,
    getStudents
};